const statuses = {
  investigating: '#DBEAFE',
  truePositive: '#EDE9FE',
  resolved: '#F3F4F6',
  inProgress: '#FEF3C7',
  falsePositive: '#FEE2E2',
};

export const palette = {
  primary: {
    main: '#111827',
  },
  secondary: {
    main: '#ff7300',
    dark: '#ff5C00',
  },
  light: '#cdb7f8',
  alt: '#7034ee',
  white: '#ffffff',
  stroke: '#E0E0E0',
  gray: {
    ['50']: '#F1F1F1',
    ['100']: '#FEFEFE',
    ['150']: '#F0EFF0',
    ['400']: '#9CA3AF',
    ['500']: '#4B5563',
    ['700']: '#374151',
  },
  yellow: {
    ['100']: '#FAF6D1',
  },
  green: {
    ['100']: '#D1FAE5',
    ['800']: '#065F46',
  },
  red: {
    ['100']: '#FFE8E3',
    ['800']: '#5F1106',
  },
  severity: {
    low: '#FFE37E',
    lowFaded: 'rgba(255, 227, 126, 0.10)',
    medium: '#FFA26D',
    mediumFaded: 'rgba(255, 162, 109, 0.10)',
    high: '#FF5B5C',
    highFaded: 'rgba(235, 76, 66, 0.10)',
    critical: '#7B0002',
    criticalFaded: 'rgba(123, 0, 2, 0.10)',
  },
  statuses,
  umbrella: '#2563EB',
};

export const ratings = {
  critical: '#bf0606',
  high: '#f56262',
  medium: '#FFBE00',
  low: '#4ADD61',
  informational: '#9DC1FD',
};

export const error = {
  main: '#E20202',
};
export const warning = {
  main: '#e37424',
};
export const success = {
  main: '#009E5C',
};
export const info = {
  main: '#e8d123',
};

export const providerColor: { [key: string]: string } = {
  crowdstrike: '#DC5339',
  umbrella: '#3CBEB1',
  elastic: '#009FD9',
};
